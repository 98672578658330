// @ts-ignore
import {Injectable} from '@angular/core';
// @ts-ignore
import {HttpClient, HttpHeaders} from '@angular/common/http';
// @ts-ignore
import {Observable} from 'rxjs';
import {ResoponseObject} from './orders.model';


@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  // private url = 'http://localhost:3000/';
  private url = 'https://nugegoda-database-api.unikwear.lk/';
  // private url = 'https://unikwear-lk-backend.bot-manager.tk/';
  // private url = 'https://unikwear-backend.herokuapp.com/';
  // private url = 'https://buyzone.bot-manager.tk/';
  // private url = 'https://buyzone.bot-manager.tk/';
  // private url = 'https://demo-expergen-buyzone-backend.bot-manager.tk/';
  // private url = 'https://buyzonelk-test.herokuapp.com/';


  constructor(private serviceHttp: HttpClient) {
  }


  /*--------------------- Get all order details --------------------------------*/
  getAllOrders(size: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'orders/all_orderDetails/' + size);
  }

  getConfirmedOrderDetails(): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'get/report_data/confirmed/mainPage');
  }

  getPendingOrderDetails(): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'get/report_data/pending/mainPage');
  }

  getAllAddedOrderDetails(): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'reports/addedOrderDetails/all');
  }

  getFilteredData(keyword: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'orders/all_orderDetails/search', {keyword: keyword});
  }

  checkPreviousItemsSearch(keyword1: any, keyword2: any, keyword3: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'orders/all_orderDetails/search/previousOrders', {
      keyword1: keyword1,
      keyword2: keyword2,
      keyword3: keyword3
    });
  }

  getFilteredData2(keyword: any, keyword2: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'orders/all_orderDetails', {
      keyword: keyword,
      keyword2: keyword2
    });
  }

  getOrderTimeline(keyword: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'get_timelineDetailsForCod/' + keyword);
  }

  getSingleOrderDetails(keyword: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'getOrderAllDetails/' + keyword);
  }

  getAllItems(): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'getAllItems');
  }


  getAllEmployees(): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'getEmployees');
  }

  getAllDeliveryTypes(): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'getAllDeliveryTypes');
  }

  getAllCities(): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'getAllCities');
  }

  addOrder(dataToSendToServer: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'orders/add/new', dataToSendToServer);
  }

  addWarranty(dataToSendToServer: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'orders/add/warranty', dataToSendToServer);
  }

  updateOrder(dataToSendToServer: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'orders/update/now', dataToSendToServer);
  }

  updateReasonToReturnOrder(orderID: any, value: any, type: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'update/return/reasons', {
      orderID: orderID,
      value: value,
      type: type
    });
  }

  updateReasonsToReturnDetails(orderID: any): Observable<ResoponseObject> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'get/returnUpdate/details', {orderID: orderID});
  }

  updateNotes(dataToSendToServer: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'update/notes', dataToSendToServer);
  }

  updateDeliveryStatus(orderId: any, deliveryStatus: any, emailAddress: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'updateDeliveryStatus', {
      orderId: orderId,
      deliveryStatus: deliveryStatus,
      agent: emailAddress
    });
  }

  removeConfirmedOrderDetails(orderId: any, deliveryStatus: any, emailAddress: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'removeConfirmedOrderDetails', {
      orderId: orderId,
      deliveryStatus: deliveryStatus,
      agent: emailAddress
    });
  }

  getAllUsers(): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'getAllUsers');
  }

  getDeliveredDateDetails(orderId: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'getDeliveredDate/' + orderId);
  }

  updateDeliveryStatusWithCOD(orderId: any, emailAddress: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'updateDeliveryStatusWithNewCOD', {
      orderId: orderId,
      agent: emailAddress
    });
  }

  updateCODWithCustomNumber(orderId: any, emailAddress: any, newNumber: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'new_custom_cod', {
      orderId: orderId,
      newNumber: newNumber,
      agent: emailAddress
    });
  }

  sendSms(number: any, message: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'send/sms/to/client/33235523423432234', {
      number: number,
      message: message
    });
  }

  getEnteredWiseReportDetails(employee: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/entered_wise', {
      employee: employee,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getItemWiseDetails(deliveryStatus: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/item-wise', {
      deliveryStatus: deliveryStatus,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getConfirmationReportDetails(employee: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/confirmed_wise', {
      employee: employee,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getProntoUploadSheetDetails(otherParameter: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/pronto-upload-sheet-details', {
      employee: otherParameter,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getPendingReportDetails(employee: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/pending_wise', {
      employee: employee,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getDeliveredReportDetails(employee: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/createToDelivered', {
      employee: employee,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getReturnedOrdersBasedDate(employee: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/returnedOrder_basedOnDay', {
      employee: employee,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getDispatchBasedOnDeliveryType(deliveryType: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/dispatchBasedOnDeliveryType', {
      deliveryType: deliveryType,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getDeliveredBasedOnDeliveryType(deliveryType: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/deliveredBasedOnDeliveryType', {
      deliveryType: deliveryType,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getDispatchedAndDeliveredOrders(deliveryType: any, fromDate: any, toDate: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/dispatched_delivered_orders', {
      deliveryType: deliveryType,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  getDeliveredDateToArray(query: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/deliveredDateToArray', {query: query});
  }

  allOrdersDetailsByIdList(query: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/allOrderDetailsByIDList', {query: query});
  }

  getReturnedDateToArray(query: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/report_data/returnedDateToArray', {query: query});
  }

  getCashAcceptedPriceList(query: any): Observable<ResoponseObject[]> {
    return this.serviceHttp.post<ResoponseObject[]>(this.url + 'get/cash-accepted-report', {query: query});
  }

  updateItemDetails(id: any, name: any, purchasePrice: any, soldPrice: any,envelope:any,category:any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'update/items', {
      id: id,
      name: name,
      purchasePrice: purchasePrice,
      soldPrice: soldPrice,
      envelope:envelope,
      category:category
    });
  }

  addItem(name: any, purchasePrice: any, soldPrice: any, category: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'add/items', {
      name: name,
      purchasePrice: purchasePrice,
      soldPrice: soldPrice,
      category: category,
    });
  }

  updateEmployeeDetails(employeeName: any, employeeEmail: any, empPhone: any, employeePost: any, activeStatus: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'update/employee', {
      employeeName: employeeName,
      employeeEmail: employeeEmail,
      empPhone: empPhone,
      employeePost: employeePost,
      activeStatus: activeStatus
    });
  }

  addEmployee(employeeName: any, employeeEmail: any, empPhone: any, employeePost: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'add/employee', {
      employeeName: employeeName,
      employeeEmail: employeeEmail,
      empPhone: empPhone,
      employeePost: employeePost
    });
  }

  changePassword(password: any, email: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'update/employee/password', {password: password, email: email});
  }

  authLogin(username: any, password: any): Observable<ResoponseObject> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'auth/login', {username: username, password: password});
  }

  updatePrivileges(username: any): Observable<ResoponseObject> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'auth/updatePrivileges', {username: username});
  }

  getAllItemCounts(): Observable<ResoponseObject[]> {
    return this.serviceHttp.get<ResoponseObject[]>(this.url + 'get/order-count');
  }

  // tslint:disable-next-line:max-line-length
  updateEmployeeAccess(employeeEmail: any, addOrdersAccess: any, confirmOrderAccess: any, deliveredOrderAccess: any, returnOrderAccess: any, canceledOrderAccess: any): Observable<Boolean> {
    return this.serviceHttp.post<Boolean>(this.url + 'update/employee/access', {
      employeeEmail: employeeEmail,
      addOrdersAccess: addOrdersAccess,
      confirmOrderAccess: confirmOrderAccess,
      deliveredOrderAccess: deliveredOrderAccess,
      returnOrderAccess: returnOrderAccess,
      canceledOrderAccess: canceledOrderAccess
    });
  }

  getSingleOrderDetailsByCOD(cod: any, agent: any): Observable<ResoponseObject> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'get/single_order/details', {cod: cod});
  }

  dispatchOrderDetails(cod: any, agent: any): Observable<ResoponseObject> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'update/delivery-status/dispatched', {
      cod: cod,
      agent: agent
    });
  }

  returnOrderDetails(cod: any, agent: any): Observable<ResoponseObject> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'update/delivery-status/return', {
      cod: cod,
      agent: agent
    });
  }

  refreshMainTableData(lastUpdatedTime: any): Observable<ResoponseObject> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'get/main-table/refresh', {lastUpdatedTime: lastUpdatedTime});
  }

  getCurrentCodDetails(): Observable<ResoponseObject> {
    return this.serviceHttp.get<ResoponseObject>(this.url + 'getCodNumbers');
  }

  getAllWarranty(): Observable<any> {
    return this.serviceHttp.get<ResoponseObject>(this.url + 'warranty/all');
  }

  getWarrantyOnScroll(indexCount:any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'warranty/for-scroll',{count:indexCount});
  }

  updateCodDetails(prefix: any, digitCount: any, fromNumber: any, toNumber: any): Observable<ResoponseObject> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'update/cod-details', {digitCount: digitCount,Prefix:prefix,maxAllowedNumber:toNumber,currentCod:fromNumber});
  }

  addWarrantyOnWarrantyTable(agentEmail: any, codNumber: any, item: any, complaint: any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'warranty/add', {codNumber: codNumber,itemCheckBy:agentEmail,customerComplaintAbout:complaint,itemId1:item});
  }

  updateWarrantyDetails(id:any,agentEmail: any, codNumber: any, item: any, complaint: any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'warranty/update', {id:id,codNumber: codNumber,itemCheckBy:agentEmail,customerComplaintAbout:complaint,itemId1:item});
  }

  updateWarrantyStatus(agentEmail: any, warrantyNumber:any,status:any,cod:any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'warranty-status/update', {cod:cod,agentEmail:agentEmail,warrantyNumber: warrantyNumber,status:status});
  }

  updateProfilePicture(empEmail: any, thumbnail_url: any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'update/employee/profile-pic', {imageURL:thumbnail_url,username:empEmail});
  }

  getSingleEmployeeData(empEmail: any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'single/employee/data', {empEmail:empEmail});
  }

  getWarrantyBySearch(keyword: any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'get/warrantyDetails/by-search', {keyword:keyword});
  }

  getCategoryAllType(): Observable<any> {
    return this.serviceHttp.get<ResoponseObject>(this.url + 'get/AllCategories');
  }

  addCategory(image_url: any, subtitle: any, subtitle_sinhala: any, title: any, title_sinhala: any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'add/category',{image_url: image_url, subtitle: subtitle, subtitle_sinhala: subtitle_sinhala, title: title, title_sinhala: title_sinhala});
  }

  updateCategory(id:any,image_url: any, subtitle: any, subtitle_sinhala: any, title: any, title_sinhala: any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'update/category',{id:id,image_url: image_url, subtitle: subtitle, subtitle_sinhala: subtitle_sinhala, title: title, title_sinhala: title_sinhala});
  }

  deleteCategory(id:any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'delete/category',{id:id});
  }

  getManufacturerAllType(): Observable<any> {
    return this.serviceHttp.get<ResoponseObject>(this.url + 'get/AllManufacturer');
  }

  addManufacturer(image_url: any, subtitle: any, subtitle_sinhala: any, title: any, title_sinhala: any,priority:any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'add/manufacturer',{image_url: image_url,priority:priority, subtitle: subtitle, subtitle_sinhala: subtitle_sinhala, title: title, title_sinhala: title_sinhala});
  }

  updateManufacturer(id:any,image_url: any, subtitle: any, subtitle_sinhala: any, title: any, title_sinhala: any, priority: any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'update/manufacturer',{id:id,priority:priority,image_url: image_url, subtitle: subtitle, subtitle_sinhala: subtitle_sinhala, title: title, title_sinhala: title_sinhala});
  }

  deleteManufacturer(id:any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'delete/manufacturer',{id:id});
  }

  updateDispatchTrackerDetails(clientStatus:any,clientComment:any,orderNumber:any,type:any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'update/dispatchTracker',{clientStatus:clientStatus,clientComment:clientComment,orderNumber:orderNumber,type:type});
  }


  getItemsAllType(): Observable<any> {
    return this.serviceHttp.get<ResoponseObject>(this.url + 'get/allItems');
  }

  updateDevice(id: any,phNumber: any, Title: any, SubTitle: any, SubTitle_sinhala: any, Warranty: any, Warranty_sinhala: any, Category: any,Manufacturer: any,Similar_Item: any,Priority: any,Status: any,Web_page: any,MainImage: any,Image1: any,Image2: any,Image3: any,Image4: any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'update/device',{id: id,phNumber: phNumber, Title: Title, SubTitle: SubTitle, SubTitle_sinhala: SubTitle_sinhala, Warranty: Warranty, Warranty_sinhala: Warranty_sinhala, Category: Category,Manufacturer: Manufacturer,Similar_Item: Similar_Item,Priority: Priority,Status: Status,Web_page: Web_page,MainImage: MainImage,Image1: Image1,Image2: Image2,Image3: Image3,Image4: Image4});
  }

  disableChatBot(id:any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'disable/chatbot',{id:id});
  }

  getOrderStatus(cod:any): Observable<any> {
    return this.serviceHttp.post<ResoponseObject>(this.url + 'orderDetails_from_pronto',{cod:cod});
  }
}
